import React, { useState } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useSnackbar } from 'react-simple-snackbar';
import { useParams } from 'react-router-dom';

const MyDatePicker = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  registerLocale('ko', ko);

  const handleDateChange = date => {
    let updatedDates;
    if (
      selectedDates.some(
        d => format(d, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'),
      )
    ) {
      updatedDates = selectedDates.filter(
        d => format(d, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd'),
      );
    } else {
      updatedDates = [...selectedDates, date];
    }
    setSelectedDates(updatedDates);
  };

  return (
    <div>
      <div className="w-1/2 pb-2">
        <div className="text-sm pb-3">
          선택된 날짜 :{' '}
          {selectedDates
            .sort((a, b) => a - b)
            .map(d => format(d, 'yyyy-MM-dd'))
            .join(', ')}
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-md md:max-w-xs">
            <DatePicker
              locale={ko}
              dateFormat="yyyy년 MM월 dd일"
              selected={null}
              onChange={handleDateChange}
              inline
              highlightDates={selectedDates}
              showIcon
              className="w-full p-2 text-sm md:text-xs"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDatePicker;
