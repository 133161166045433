import React from 'react';
import CheckReservation from './CheckReservation';

const DashBoardReservation = () => {
  return (
    <div>
      <CheckReservation />
    </div>
  );
};

export default DashBoardReservation;
